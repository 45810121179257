<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.inspections_infringement_report') }}</h1>
        <FiltersBar :with-search="false"
                    @reset="setYearFilter"
                    ref="filtersBar"></FiltersBar>

        <InspectionsInfringementTable :loader="loading"
                                      :xls-loader="xlsLoader"
                                      :pdf-loader="pdfLoader"
                                      @get-file="getFile($event, filesParams)"
                                      :filters="generateFilters()"></InspectionsInfringementTable>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import debounce from 'lodash.debounce';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import InspectionsInfringementTable from '@/components/reports/InspectionsInfringementTable';
import filtering from '@/mixins/filtering';
import debouncedInspectors from '@/mixins/debounced/debouncedInspectors';
import reportsFiles from '@/mixins/reportsFiles';
import YearPickerFilter from '@/models/filters/yearPickerFilter';
import AutocompleteFilter from '@/models/filters/autocompleteFilter';

export default {
    name: 'InspectionsInfringementReport',
    mixins: [filtering, debouncedInspectors, reportsFiles],
    components: { FiltersBar, InspectionsInfringementTable },
    data: () => ({
        pagination: {
            page: 1,
            amount: 20,
            amountAll: null
        },
        loading: false,
        debouncedGetInspectionsInfringement: null
    }),
    computed: {
        currentYear () {
            return new Date().getFullYear();
        },
        filtersConfig () {
            return [
                new YearPickerFilter({
                    model: FILTERS.CHOSEN_YEAR,
                    label: this.$t('labels.year'),
                    max: `${this.currentYear}-NaN-NaN`
                }),
                new AutocompleteFilter({
                    model: FILTERS.PERSON_ID,
                    label: this.$tc('labels.inspector', 1),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.INSPECTOR,
                    lazy: this.getInspectorsLazyLoading,
                    searchCallback: this.debouncedGetInspectors,
                    items: {
                        obj: 'getters',
                        module: 'employees',
                        prop: 'inspectors'
                    }
                })
            ];
        },
        ...mapState('filters', [
            'filters',
            'filtersSearching'
        ]),
        year () {
            return this.filters[FILTERS.CHOSEN_YEAR] || this.currentYear;
        },
        filesParams () {
            return {
                kind: 'incorrect',
                ...this.generateFilters()
            };
        }
    },
    methods: {
        getInspectionsInfringement () {
            this.loading = true;

            const params = {
                ...this.generateFilters()
            };

            this.$store.dispatch('reports/getInspectionsInfringement', params)
                .finally(() => {
                    this.loading = false;
                });
        },
        generateFilters () {
            const params = {
                year: this.year
            };

            this.filtersConfig.forEach(filter => {
                const f = filter.model;

                if (this.filters[f] && this.filters[f].length > 0) {
                    if (f !== FILTERS.CHOSEN_YEAR) {
                        params[f] = this.filters[f].join(',');
                    }
                }
            });

            return params;
        },
        setYearFilter () {
            this.$refs.filtersBar.setSpecifyFilter(FILTERS.CHOSEN_YEAR, this.year.toString());
        }
    },
    created () {
        this.debouncedGetInspectionsInfringement = debounce(this.getInspectionsInfringement, 1000);
    },
    mounted () {
        this.setFiltersWatcher(this.debouncedGetInspectionsInfringement);
        this.setYearFilter();
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.INCORRECT_INSPECTIONS_REPORT, vm.$abilitySubjects.REPORTS)) {
                vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                vm.$store.dispatch('employees/getInspectors')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.PERSON_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('filters/clearFiltersParams');
        next();
    }
};
</script>

<style scoped>

</style>
