<template>
    <Modal :modal-title="title"
           :dialog="dialog"
           max-width="1800px"
           dense
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <FiltersBar :with-search="false"
                        :is-modal="isModal"
                        ref="filtersBar"></FiltersBar>
            <InspectorReportTable :loader="loader"
                                  :xls-loader="xlsLoader"
                                  :pdf-loader="pdfLoader"
                                  :filters="mailParams"
                                  @get-file="getFile($event, filesParams)"></InspectorReportTable>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import debounce from 'lodash.debounce';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';
import Modal from '@/components/templates/Modal';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import InspectorReportTable from '@/components/reports/reportsPerInspector/InspectorReportTable';
import AutocompleteFilter from '@/models/filters/autocompleteFilter';
import debouncedCustomers from '@/mixins/debounced/debouncedCustomers';
import debouncedSites from '@/mixins/debounced/debouncedSites';
import debouncedLocations from '@/mixins/debounced/debouncedLocations';
import filtering from '@/mixins/filtering';
import reportsFiles from '@/mixins/reportsFiles';

export default {
    name: 'InspectorReport',
    mixins: [debouncedCustomers, debouncedSites, debouncedLocations, filtering, reportsFiles],
    components: { Modal, InspectorReportTable, FiltersBar },
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        inspectorName: {
            type: String,
            'default': ''
        },
        year: {
            type: [String, Number],
            required: true
        },
        inspectorId: {
            type: [String, Number],
            required: true
        }
    },
    data: () => ({
        isModal: true,
        loader: false,
        debouncedFetchInspectorReport: null
    }),
    computed: {
        ...mapState('filters', [
            'modalFilters',
            'modalFiltersSearching'
        ]),
        title () {
            return `${this.$t('labels.inspector_report')}: ${this.inspectorName}`;
        },
        modalFiltersConfig () {
            return [
                new AutocompleteFilter({
                    model: FILTERS.CUSTOMER_ID,
                    label: this.$t('labels.customer'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.CUSTOMER,
                    lazy: this.getCustomersLazyLoading,
                    referralCallback: this.referralCustomerCallback,
                    searchCallback: this.debouncedGetCustomers,
                    items: {
                        obj: 'getters',
                        module: 'customers',
                        prop: 'listingCustomers'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.SITE_ID,
                    label: this.$t('labels.site'),
                    loading: false,
                    multiple: true,
                    disabled: true,
                    successMessages: this.$t('messages.choose_customer'),
                    search: FILTERS_SEARCHING.SITE_PER_CUSTOMER,
                    lazy: this.getSitesLazyLoading,
                    referralCallback: this.referralSiteCallback,
                    searchCallback: this.debouncedGetSites,
                    searchOptions: {
                        reference: FILTERS.CUSTOMER_ID
                    },
                    items: {
                        obj: 'getters',
                        module: 'sites',
                        prop: 'sites'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.LOCATION_ID,
                    label: this.$t('labels.location'),
                    loading: false,
                    multiple: true,
                    disabled: true,
                    successMessages: this.$t('messages.choose_site'),
                    search: FILTERS_SEARCHING.LOCATION,
                    lazy: this.getLocationsLazyLoading,
                    searchCallback: this.debouncedGetLocations,
                    searchOptions: {
                        reference: FILTERS.SITE_ID
                    },
                    items: {
                        obj: 'getters',
                        module: 'locations',
                        prop: 'locations'
                    }
                })
            ];
        },
        filesParams () {
            return {
                kind: 'customers',
                employee_id: this.inspectorId,
                year: this.year,
                ...this.generateFiltersForRequest()
            };
        },
        mailParams () {
            const params = {
                employee_id: this.inspectorId.toString(),
                year: this.year,
                filter: {}
            };

            this.modalFiltersConfig.forEach(filter => {
                const f = filter.model;

                if (this.modalFilters[f] && this.modalFilters[f].length > 0) {
                    params.filter[f] = this.modalFilters[f].join(',');
                }
            });

            return params;
        }
    },
    watch: {
        dialog: {
            handler (val) {
                if (val) {
                    this.debouncedFetchInspectorReport = debounce(this.fetchInspectorReport, 1000);
                    this.setFilters();
                    this.setFiltersWatcher(this.debouncedFetchInspectorReport);
                }
            },
            immediate: true
        }
    },
    methods: {
        close () {
            this.$emit('close');
            this.$store.dispatch('filters/clearModalFiltersParams');
            this.$refs.filtersBar.clearFilters();
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        setFilters () {
            this.$store.commit('filters/SET_MODAL_CONFIG', this.modalFiltersConfig);
            this.getCustomers()
                .then(() => {
                    this.modalFiltersConfig.find(el => el.model === FILTERS.CUSTOMER_ID).loading = false;
                    this.$store.commit('filters/SET_MODAL_CONFIG', this.modalFiltersConfig);
                });
        },
        generateFiltersForRequest () {
            const params = {};

            this.modalFiltersConfig.forEach(filter => {
                const f = filter.model;

                if (this.modalFilters[f] && this.modalFilters[f].length > 0) {
                    params[`filter[${f}]`] = this.modalFilters[f].join(',');
                }
            });

            return params;
        },
        fetchInspectorReport () {
            this.loader = true;

            const params = {
                employee_id: this.inspectorId,
                year: this.year,
                ...this.generateFiltersForRequest()
            };

            return this.$store.dispatch('reports/getInspectionsPerEmployee', params)
                .finally(() => {
                    this.loader = false;
                });
        }
    }
};
</script>

<style scoped>

</style>
