<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-card flat>
        <v-card-title>
            {{ $t('planning_reports.details') }}
            <v-layout justify-end>
                <v-btn color="secondary" outlined class="ml-4" @click="close">{{ $t('buttons.cancel') }}</v-btn>
            </v-layout>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-sheet color="white"
                             class="px-4"
                             elevation="1">
                        <v-row>
                            <v-col>
                                <InformationItem :title="$t('labels.type')"
                                                 :text="getPeriodicReportType(item.type)"
                                                 name="type"></InformationItem>
                                <InformationItem v-if="reportHasParam(PERIODIC_REPORTS_PARAMS.POINT)"
                                                 :title="$t('labels.point')"
                                                 :text="item.pointName"
                                                 name="point_type"></InformationItem>
                                <InformationItem v-else-if="reportHasParam(PERIODIC_REPORTS_PARAMS.LOCATION)"
                                                 :title="$t('labels.location')"
                                                 :text="item.locationName"
                                                 name="location_type"></InformationItem>
                                <InformationItem v-else-if="reportHasParam(PERIODIC_REPORTS_PARAMS.EMPLOYEE)"
                                                 :title="$t('labels.user')"
                                                 :text="item.employeeName"
                                                 name="user_type"></InformationItem>
                            </v-col>
                            <v-col>
                                <InformationItem :title="$t('labels.is_paused')"
                                                 :text="item.isPaused"
                                                 name="is_paused"></InformationItem>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-layout justify-space-between :wrap="$vuetify.breakpoint.xs">
                <h3 class="font-weight-medium my-2">
                    {{ $t('planning_reports.reporting_params') }}</h3>
            </v-layout>
            <v-row>
                <v-col cols="12">
                    <v-sheet color="white"
                             class="px-4"
                             elevation="1">
                        <v-row>
                            <v-col cols="12" md="6">
                                <InformationItem :title="$t('labels.start_date')"
                                                 :text="item.startDateReporting"
                                                 name="star_date"></InformationItem>
                                <InformationItem :title="$t('labels.start_time')"
                                                 :text="item.timeToInvokeReport"
                                                 name="start_time"></InformationItem>
                                <InformationItem :title="$t('labels.frequncy_type')"
                                                 :text="$t(`planning_reports.frequency_types.${item.frequencyType}`)"
                                                 name="frequency_type"></InformationItem>
                            </v-col>
                            <v-col cols="12" md="6">
                                <InformationItem :title="$t('labels.end_date')"
                                                 :text="item.endDateReporting"
                                                 name="end_date"></InformationItem>
                            </v-col>
                            </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-layout justify-space-between :wrap="$vuetify.breakpoint.xs">
                <h3 class="font-weight-medium my-2">
                    {{ $t('planning_reports.recepients') }}</h3>
            </v-layout>
            <v-row>
                <v-col cols="12">
                    <v-sheet color="white"
                             class="px-4"
                             elevation="1">
                        <v-row>
                            <v-col>
                                <v-combobox
                                    :label="$t('labels.notification_email')"
                                    v-model="emails"
                                    multiple
                                    readonly
                                ></v-combobox>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import InformationItem from '@/components/customers/InformationItem';
import { PERIODIC_REPORTS_PARAMS } from '@/constants/reports_params';
import { PERIODIC_REPORTS_TYPES } from '@/constants/reports';

export default {
    name: 'PlanningReportsDetails',
    components: {
        InformationItem
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        itemTypes: {
            type: Array,
            required: true
        },
        frequencyTypes: {
            type: Array,
            required: true
        }
    },
    watch: {
        item: {
            handler () {
                this.emails = this.item.emailsForNotifications.map(e => e.email);
            },
            immediate: true
        }
    },
    data: () => ({
        PERIODIC_REPORTS_PARAMS,
        PERIODIC_REPORTS_TYPES,
        emails: [],
        getPeriodicReportType (type) {
            return this.itemTypes.find(item => item.value === type)?.text;
        },
        getNotifiersDetails (notifiers) {
            return notifiers.map(n => n.email).join(', ');
        }
    }),
    methods: {
        reportHasParam (param) {
            const reportType = this.PERIODIC_REPORTS_TYPES.find(r => this.item.type === r.type);
            return reportType.param === param;
        },
        close () {
            this.$emit('close-details');
        }
    }
};
</script>
