import debounce from 'lodash.debounce';
import { mapState, mapGetters } from 'vuex';
import { FILTERS_SEARCHING } from '@/constants/filtersModelNames';

export default {
    data: () => ({
        debouncedGetInspectors: null,
        isInspectorsLoading: false,
        searchInspectors: null,
        inspectorsPaginationPage: 1
    }),
    computed: {
        ...mapState('employees', [
            'totalInspectorsAmount'
        ]),
        ...mapGetters('employees', [
            'inspectors'
        ])
    },
    watch: {
        searchInspectors: {
            handler (val) {
                if (!val || this.isInspectorsLoading) {
                    return;
                }

                this.debouncedGetInspectors(val);
            }
        }
    },
    methods: {
        getInspectors (title) {
            this.isInspectorsLoading = true;

            const params = {
                'filter[full_name][match]': title
            };

            return this.$store.dispatch('employees/getInspectors', params)
                .finally(() => {
                    this.isInspectorsLoading = false;
                });
        },
        getInspectorsLazyLoading () {
            if (this.totalInspectorsAmount > this.inspectors.length) {
                this.isInspectorsLoading = true;

                this.inspectorsPaginationPage += 1;
                const params = {
                    'page[number]': this.inspectorsPaginationPage
                };

                const _name = this.searchInspectors || this.filtersSearching?.[FILTERS_SEARCHING.INSPECTOR];

                if (_name) {
                    params['filter[full_name][match]'] = _name;
                }

                return this.$store.dispatch('employees/getInspectorsLazyLoading', params)
                    .finally(() => {
                        this.isInspectorsLoading = false;
                    });
            }
        }
    },
    created () {
        this.debouncedGetInspectors = debounce(this.getInspectors, 1000);
    }
};
