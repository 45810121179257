<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-card flat>
        <div class="alert_placeholder">
            <v-alert
                :value="this.$props.alert_email_sent || this.$props.alert_email_error"
                border="top"
                :color="this.$props.alert_type === 'info' ? 'secondary' : this.$props.alert_type"
                dismissible
                elevation="8"
                outlined
                :type="this.$props.alert_type"
                transition="scale-transition"
            >{{this.$props.alert_email_error ?  this.$props.alert_error : $t(this.$props.alert_message)}}</v-alert>
        </div>

        <v-card-title>{{ $t('custom_reports.edit_title') }}
            <v-layout justify-end>
                <v-btn color="secondary" outlined class="ml-4" @click="close">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn color="secondary" :disabled=this.$props.saveIsDisabled class="ml-4" @click="save">
                    {{ generateNow ? $t('buttons.send') : $t('buttons.save') }}
                </v-btn>
            </v-layout>
        </v-card-title>
        <v-card-text>
            <ValidationObserver ref="obs">
                <v-row>
                    <v-col cols="12" md="6">
                        <SelectField :items="itemTypes"
                                     :disabled="editedPlannedReportId !== null"
                                     name="report"
                                     v-model="type">
                        </SelectField>

                        <template v-if="isTypeOf(this.PERIODIC_REPORTS_PARAMS.POINT)">
                            <Autocomplete
                                v-if="editedPlannedReportId === null"
                                clearable
                                rules="required"
                                :label="$t('labels.point')"
                                :items="locations"
                                :loading="isLocationsLoading"
                                :search-input.sync="searchLocations"
                                @focus="getLocations()"
                                @click:clear="getLocations()"
                                @load-more-items="getLocationsLazyLoading()"
                                v-model="pointId"
                            />
                            <TextField v-else disabled :label="$t('labels.point')" v-model="pointName"></TextField>
                        </template>
                        <template v-else-if="isTypeOf(this.PERIODIC_REPORTS_PARAMS.LOCATION)">
                            <Autocomplete
                                v-if="editedPlannedReportId === null"
                                clearable
                                rules="required"
                                :label="$t('labels.location')"
                                :items="sites"
                                :loading="isSitesLoading"
                                :search-input.sync="searchSites"
                                @focus="getSites()"
                                @click:clear="getSites()"
                                @load-more-items="getSitesLazyLoading()"
                                v-model="locationId"
                            />
                            <TextField v-else disabled :label="$t('labels.location')" v-model="locationName"></TextField>
                        </template>
                        <template v-else-if="isTypeOf(PERIODIC_REPORTS_PARAMS.EMPLOYEE)">
                            <Autocomplete
                                v-if="editedPlannedReportId === null"
                                clearable
                                rules="required"
                                :label="$t('navigation.employees')"
                                :items="employees"
                                :loading="isEmployeesLoading"
                                :search-input.sync="searchEmployees"
                                @focus="getEmployees(baseEmployeeFilters)"
                                @click:clear="getEmployees(baseEmployeeFilters)"
                                @load-more-items="getEmployeesLazyLoading(baseEmployeeFilters)"
                                v-model="employeeId"
                            />
                            <TextField v-else disabled :label="$t('navigation.employees')" v-model="employeeName"></TextField>
                        </template>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <p>{{ $t('planning_reports.reporting_params') }}</p>
                        <hr class="my-5">
                        <Checkbox v-model="isPaused"
                                  :label="$t('labels.is_paused')"
                                  name="is_paused"></Checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="3">
                        <DatePicker :label="$t('labels.start_date')"
                                    name="start_date"
                                    clearable
                                    rules="required"
                                    v-model="startDateReporting"></DatePicker>
                    </v-col>
                    <v-col cols="12" md="3">
                        <DatePicker :label="$t('labels.end_date')"
                                    ref="end_date_datapicker"
                                    name="end_date"
                                    clearable
                                    rules="isDateGreaterOrEqual:@start_date|required"
                                    v-model="endDateReporting"></DatePicker>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-btn
                            color="secondary"
                            class="ml-4"
                            outlined
                            @click="setNoEndDate">
                            {{$t('buttons.no_end_date')}}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row  v-if="generateNow">
                    <v-col cols="12" md="3">
                        <TimePicker :label="$t('labels.start_time')"
                                    name="start_time"
                                    clearable
                                    v-model="startTimeReporting"></TimePicker>
                    </v-col>
                    <v-col cols="12" md="3">
                        <TimePicker :label="$t('labels.end_time')"
                                    name="end_time"
                                    rules="isDateTimeGreaterThan:@start_time,@start_date,@end_date"
                                    clearable
                                    v-model="endTimeReporting"></TimePicker>
                    </v-col>
                </v-row>
                <v-row v-if="!generateNow">
                    <v-col cols="12" md="3">
                        <SelectField :label="$t('labels.frequency')"
                                     name="cycle"
                                     clearable
                                     rules="required"
                                     :items="frequencyTypes"
                                     v-model="frequencyType">
                        </SelectField>
                    </v-col>
                </v-row>
                <v-row v-if="!generateNow">
                    <v-col cols="12" md="3">
                        <TimePicker :label="$t('labels.send_time')"
                                    rules="required"
                                    name="send_time"
                                    use-seconds
                                    v-model="timeToInvokeReport"></TimePicker>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <p> {{ $t('planning_reports.recepients') }}</p>
                        <hr class="my-5">
                    </v-col>
                    <v-col cols="12">
                        <v-combobox
                            v-model="emailsForNotifications"
                            clearable
                            dense
                            filled
                            multiple
                            solo
                            @change="addEmailAction"
                            @keyup.enter="addEmailAction"
                            :error-messages="errorMessages"
                            :items="emailsForNotifications"
                            :label="$t('labels.notification_email')">
                            <template v-slot:selection="{ attrs, item, select, selected }">
                                <v-chip
                                    v-bind="attrs"
                                    :input-value="selected"
                                    close
                                    outlined
                                    @click:close="remove(item)">
                                    {{ item.email }}
                                </v-chip>
                            </template>
                            <template v-slot:item="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.email }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-combobox>
                    </v-col>
                </v-row>
            </ValidationObserver>
        </v-card-text>
    </v-card>
</template>

<script>
import { ICONS } from '@/constants/icons';
import { ValidationObserver } from 'vee-validate';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import Checkbox from '@/components/widgets/forms/Checkbox';
import DatePicker from '@/components/widgets/forms/DatePicker';
import SelectField from '@/components/widgets/forms/SelectField';
import TimePicker from '@/components/widgets/forms/TimePicker';
import TextField from '@/components/widgets/forms/TextField';
import debouncedLocations from '@/mixins/debounced/debouncedLocations';
import debouncedEmployees from '@/mixins/debounced/debouncedEmployees';
import debouncedEmployeesWithEmail from '@/mixins/debounced/debouncedEmployeesWithEmail';
import { mapFields } from 'vuex-map-fields';
import { PERIODIC_REPORTS_TYPES } from '@/constants/reports';
import { PERIODIC_REPORTS_PARAMS } from '@/constants/reports_params';
import debouncedSites from '@/mixins/debounced/debouncedSites';

export default {
    name: 'PlanningReportsForm',
    components: { ValidationObserver, Autocomplete, DatePicker, SelectField, TimePicker, Checkbox, TextField },
    mixins: [
        debouncedEmployeesWithEmail,
        debouncedSites, // actually Locations/Obiekty
        debouncedLocations, // actually Points/Punkty
        debouncedEmployees
    ],
    props: {
        generateNow: {
            type: Boolean,
            'default': false
        },
        itemTypes: {
            type: Array,
            required: true
        },
        frequencyTypes: {
            type: Array,
            required: true
        },
        alert_email_sent: {
            type: Boolean,
            required: true
        },
        alert_email_error: {
            type: Boolean,
            required: true
        },
        alert_message: {
            type: String,
            required: false
        },
        alert_error: {
            type: String,
            required: false
        },
        alert_type: {
            type: String,
            required: true
        },
        saveIsDisabled: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        ICONS,
        PERIODIC_REPORTS_PARAMS,
        PERIODIC_REPORTS_TYPES,
        timeDialog: false,
        selectedDays: [],
        generatingTimes: [],
        time: null,
        // employees: [],
        errorMessages: [],
        baseEmployeeFilters: {
            'filter[is_item]': false
        }
    }),
    computed: {
        ...mapFields('managePlannedReports', [
            'editedPlannedReportId',
            'plannedReport.type',
            'plannedReport.emailsForNotifications',
            'plannedReport.startDateReporting',
            'plannedReport.endDateReporting',
            'plannedReport.startTimeReporting',
            'plannedReport.endTimeReporting',
            'plannedReport.pointId',
            'plannedReport.pointName',
            'plannedReport.locationId',
            'plannedReport.locationName',
            'plannedReport.employeeId',
            'plannedReport.employeeName',
            'plannedReport.timeToInvokeReport',
            'plannedReport.frequencyType',
            'plannedReport.isPaused'
        ])
    },
    watch: {
    },
    methods: {
        addEmailAction () {
            const emailsToAdd = this.emailsForNotifications.filter(e => typeof (e) === 'string');
            this.emailsForNotifications = this.emailsForNotifications.filter(e => typeof (e) !== 'string');
            this.emailsForNotifications.push(...emailsToAdd.map(e => ({ email: e })));
            this.emailsForNotifications = [...this.emailsForNotifications.filter((e, i, a) => a.findIndex(el => el.email === e.email) === i)]; // remove duplicates
        },
        emailRule (email) {
            email.forEach(item => {
                const isEmailCorrect = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    .test(item);
                if (!isEmailCorrect) {
                    this.errorMessages.push(item + ' ' + this.$t('planningReports.is_not_a_valid_email'));
                }
            });
            return this.errorMessages.length === 0;
        },
        remove (item) {
            this.emailsForNotifications = this.emailsForNotifications.filter(email => email !== item);
        },
        close () {
            this.$emit('close');
        },
        save () {
            this.$emit('savedForm', this.$t('planningReports.email_sent'));
            if (this.editedPlannedReportId) {
                this.$emit('save');
            } else {
                this.$emit('create');
            }
        },
        validate () {
            this.errorMessages = [];
            if (this.emailsForNotifications.length === 0) {
                this.errorMessages.push(this.$t('planningReports.email_is_required'));
                return new Promise((resolve) => {
                    resolve(false);
                });
            } else {
                const isEmailValid = this.emailRule(this.emailsForNotifications.map(e => e.email));
                if (!isEmailValid) {
                    return new Promise((resolve) => {
                        resolve(false);
                    });
                }
            }
            return this.$refs.obs.validate();
        },
        resetForm () {
            this.errorMessages = [];
            this.$refs.obs.reset();
        },
        setNoEndDate () {
            this.$refs.end_date_datapicker.$emit('input', '2099-12-31');
        },
        isTypeOf (type) {
            return this.PERIODIC_REPORTS_TYPES.find(r => r.type === this.type)?.param === type;
        }
    }
};
</script>
<style>
.alert_placeholder{
    padding: 10px;
}
</style>
