<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('ezt.title') }}</h1>
        <FiltersBar :withSearch="false"
                    ref="filtersBar"></FiltersBar>
        <EZTReportsTable :loader="loader"
                         :totalDuration="totalDuration"
                         @get-file="getFile($event, filesParams)"
                         @fetch="fetchEZTReports"
                         @sort="sortingColumn = $event"
                         @change-page="changePage($event)"
                         @change-amount="changeAmount($event)"
        ></EZTReportsTable>
    </div>
</template>

<script>

import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';
import { GET_HUMANIZE_DURATION } from '@/helpers/dates';
import debounce from 'lodash.debounce';
import filtering from '@/mixins/filtering';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import reportsFiles from '@/mixins/reportsFiles';
import EZTReportsTable from '@/components/reports/EZTReportsTable';
import DatePickerFilter from '@/models/filters/datePickerFilter';
import AutocompleteFilter from '@/models/filters/autocompleteFilter';
import debouncedInspectors from '@/mixins/debounced/debouncedInspectors';
import debouncedCustomers from '@/mixins/debounced/debouncedCustomers';
import debouncedSites from '@/mixins/debounced/debouncedSites';
import debouncedTemplates from '@/mixins/debounced/debouncedTemplates';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'EztReports',
    components: { FiltersBar, EZTReportsTable },
    mixins: [
        filtering,
        debouncedInspectors,
        debouncedCustomers,
        debouncedSites,
        debouncedTemplates,
        paginationSettings,
        reportsFiles
    ],
    data: (vm) => ({
        pagination: vm.setupPagination(),
        debouncedFetchEZTReports: null,
        loader: false,
        sortingColumn: null
    }),
    computed: {
        ...mapState('filters', [
            'filters',
            'filtersSearching'
        ]),
        ...mapFields('search', [
            'search'
        ]),
        ...mapState('microserviceReports', [
            'eztReportsTotalDuration'
        ]),
        ...mapState('locale', [
            'currentLocale'
        ]),
        totalDuration () {
            return GET_HUMANIZE_DURATION(this.eztReportsTotalDuration, this.currentLocale);
        },
        filesParams () {
            return {
                kind: 'ezt',
                format: 'xls',
                headers: [
                    this.$t('ezt.report.seq'),
                    this.$t('ezt.report.name'),
                    this.$t('ezt.report.surname'),
                    this.$t('ezt.report.object'),
                    this.$t('ezt.report.client'),
                    this.$t('ezt.report.enter'),
                    this.$t('ezt.report.exit'),
                    this.$t('ezt.report.duration')
                ],
                ...this.generateFilters()
            };
        },
        filtersConfig () {
            return [
                new DatePickerFilter({
                    model: FILTERS.START_DATE,
                    label: this.$t('labels.date_from')
                }),
                new DatePickerFilter({
                    model: FILTERS.END_DATE,
                    label: this.$t('labels.date_to')
                }),
                new AutocompleteFilter({
                    model: FILTERS.START_TEMPLATE_ID,
                    label: this.$tc('labels.start_template', 2),
                    loading: true,
                    search: FILTERS_SEARCHING.START_TEMPLATE,
                    lazy: this.getTemplatesLazyLoading,
                    referralCallback: this.referralStartTemplateCallback,
                    searchCallback: this.debounceGetTemplates,
                    items: {
                        obj: 'getters',
                        module: 'templates',
                        prop: 'filterableTemplates'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.END_TEMPLATE_ID,
                    label: this.$tc('labels.end_template', 2),
                    loading: false,
                    disabled: true,
                    successMessages: this.$t('messages.choose_start_template'),
                    search: FILTERS_SEARCHING.END_TEMPLATE,
                    lazy: this.getSecondTemplatesLazyLoading,
                    searchCallback: this.debounceGetSecondTemplates,
                    items: {
                        obj: 'getters',
                        module: 'templates',
                        prop: 'secondFilterableTemplates'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.INSPECTOR_ID,
                    label: this.$tc('labels.inspector', 2),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.INSPECTOR,
                    lazy: this.getInspectorsLazyLoading,
                    searchCallback: this.debouncedGetInspectors,
                    items: {
                        obj: 'getters',
                        module: 'employees',
                        prop: 'inspectors'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.CUSTOMER_ID,
                    label: this.$t('labels.customers'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.CUSTOMER,
                    lazy: this.getCustomersLazyLoading,
                    referralCallback: this.referralCustomerCallback,
                    searchCallback: this.debouncedGetCustomers,
                    items: {
                        obj: 'getters',
                        module: 'customers',
                        prop: 'listingCustomers'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.SITE_ID,
                    label: this.$t('labels.sites'),
                    loading: false,
                    multiple: true,
                    disabled: true,
                    search: FILTERS_SEARCHING.SITE_PER_CUSTOMER,
                    lazy: this.getSitesLazyLoading,
                    searchCallback: this.debouncedGetSites,
                    items: {
                        obj: 'getters',
                        module: 'sites',
                        prop: 'sites'
                    }
                })
            ];
        }
    },
    watch: {
        search: {
            handler (val, oldVal) {
                if ((val && val.length >= 2) || (oldVal && val === '')) {
                    this.pagination.page = 1;
                    this.fetchEZTReports();
                }
            }
        },
        sortingColumn: {
            handler () {
                this.fetchEZTReports();
            },
            deep: true
        }
    },
    methods: {
        generateFilters () {
            const params = {};
            this.filtersConfig.forEach(filter => {
                const f = filter.model;

                if (this.filters[f] && this.filters[f].length > 0) {
                    if (f === FILTERS.START_DATE || f === FILTERS.END_DATE) {
                        const value = f === FILTERS.START_DATE
                            ? `${this.filters[f]} 00:00:00`
                            : `${this.filters[f]} 23:59:59`;
                        params[f] = value;
                    } else {
                        params[f] = this.filters[f];
                    }
                }
            });
            return params;
        },
        fetchEZTReports () {
            this.loader = true;
            const params = {
                limit: this.pagination.amount,
                sort_by: '-wejscie',
                start_template_id: '48',
                end_template_id: '50',
                ...this.generateFilters()
            };

            if (this.pagination.page === 1) {
                params.offset = 0;
            } else {
                params.offset = (this.pagination.page - 1) * this.pagination.amount;
            }

            if (this.sortingColumn) {
                params.sort_by = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }

            return this.$store.dispatch('microserviceReports/getEztReports', {
                params: params
            }).finally(() => {
                this.loader = false;
            });
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchEZTReports();
        },
        changeAmount (event) {
            this.pagination.amount = event;
            this.fetchEZTReports();
        }
    },
    created () {
        this.debouncedFetchEZTReports = debounce(this.fetchEZTReports, 1000);
    },
    mounted () {
        this.setFiltersWatcher(this.debouncedFetchEZTReports);
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
            vm.$store.dispatch('employees/getInspectors')
                .then(() => {
                    vm.filtersConfig.find(el => el.model === FILTERS.INSPECTOR_ID).loading = false;
                    vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                });
            vm.$store.dispatch('customers/getCustomers')
                .then(() => {
                    vm.filtersConfig.find(el => el.model === FILTERS.CUSTOMER_ID).loading = false;
                    vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                });
            vm.$store.dispatch('templates/getTemplates', {
                'filter[archive]': false,
                'filter[current]': true
            })
                .then(() => {
                    vm.filtersConfig.find(el => el.model === FILTERS.START_TEMPLATE_ID).loading = false;
                    vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                });
            vm.$store.dispatch('templates/getTemplates', {
                secondTemplate: true,
                'filter[archive]': false,
                'filter[current]': true
            })
                .then(() => {
                    vm.filtersConfig.find(el => el.model === FILTERS.END_TEMPLATE_ID).loading = false;
                    vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                });
        });
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('filters/clearFiltersParams');
        next();
    }
};

</script>

<style scoped>

</style>
