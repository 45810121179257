<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-layout align-center
                  class="mb-8">
            <GetFile :loader="pdfLoader"
                     class="mr-3"
                     @get-file="emitGetFile('pdf')">
            </GetFile>
            <GetFile :title="$t('buttons.export_xls')"
                     :loader="xlsLoader"
                     @get-file="emitGetFile('xls')">
                <template slot="icon">
                    <v-icon class="mr-1"
                            size="20">{{ ICONS.EXPORT }}</v-icon>
                </template>
            </GetFile>
            <v-btn color="secondary"
                   small
                   @click="showSendMail"
                   class="ml-3">{{ $t('buttons.send_mail') }}</v-btn>
            <SendMail :dialog="sendMailVisibility"
                      :callback="sendMail"
                      :default-title="`${$t('navigation.inspections_infringement_report')}`"
                      @close="closeSendMail"
                      @visibility="sendMailVisibility = $event"></SendMail>
        </v-layout>
        <p class="text--xs mb-1"><span class="red--text font-weight-medium">*</span> {{ $t('messages.marked_incorrect_inspections') }}</p>
        <v-data-table :headers="headers"
                      :loading="loader"
                      :items="inspectionsInfringement"
                      disable-pagination
                      hide-default-footer
                      @dblclick:row="showRowDetails"
                      class="elevation-1">
            <template v-for="(headerItem, i) in headers"
                      v-slot:[`header.${headerItem.value}`]="{ header, on }">
                <SortableHeader :header-item="header"
                                v-on="on"
                                :key="i"></SortableHeader>
            </template>

            <template v-slot:item.fullName="{ item }">
                <span class="clickable px-4"
                      @click="showInspectorReport(item)">{{ item.fullName }}</span>
            </template>
            <template v-slot:item.year="{ item }">
                <div v-for="(year, i) in item.data"
                     :class="getClass(i)"
                     class="px-4"
                     :key="i">{{ year.year }}</div>
            </template>
            <template v-slot:item.count="{ item }">
                <div v-for="(year, i) in item.data"
                     class="px-4"
                     :class="getClass(i)"
                     :key="i">{{ year.count }}</div>
            </template>
            <template v-slot:item.incorrectCount="{ item }">
                <div v-for="(year, i) in item.data"
                     :class="getClass(i)"
                     class="px-4"
                     :key="i">{{ year.incorrectCount }}</div>
            </template>
            <template v-for="(i) in 12"
                      v-slot:[`item.month_${i}`]="{ item }">
                <div :key="i">
                    <div v-for="(year, j) in item.data"
                         :class="getClass(j)"
                         class="px-4"
                         :key="j">
                        <span>
                            {{ year.monthsData[`month_${i}`].count }}/<span class="red--text font-weight-medium">{{ year.monthsData[`month_${i}`].incorrectCount }}</span>
                        </span>
                    </div>
                </div>
            </template>
        </v-data-table>

        <InspectorReport :dialog="inspectorReportVisibility"
                         v-if="inspectorReportVisibility"
                         :inspector-name="currentInspectorName"
                         :year="filters.year"
                         :inspector-id="chosenEmployee"
                         @visibility="inspectorReportVisibility = $event"
                         @close="closeInspectorReport"></InspectorReport>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ICONS } from '@/constants/icons';
import InspectorReport from '@/components/reports/reportsPerInspector/InspectorReport';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import GetFile from '@/components/widgets/buttons/GetFile';
import SendMail from '@/components/widgets/dialogs/SendMail';

export default {
    name: 'InspectionsInfringementTable',
    components: { InspectorReport, SortableHeader, GetFile, SendMail },
    props: {
        loader: {
            type: Boolean,
            'default': false
        },
        xlsLoader: {
            type: Boolean,
            'default': false
        },
        pdfLoader: {
            type: Boolean,
            'default': false
        },
        filters: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        inspectorReportVisibility: false,
        currentInspectorName: '',
        chosenEmployee: '',
        ICONS,
        sendMailVisibility: false
    }),
    computed: {
        ...mapState('reports', [
            'inspectionsInfringement'
        ]),
        ...mapState('generalConfigurations', [
            'host'
        ]),
        ...mapFields('sendMail', [
            'mailData.agency',
            'mailData.kindOfReport',
            'mailData.report'
        ]),
        headers () {
            return [
                {
                    text: this.$tc('labels.inspector', 1),
                    value: 'fullName',
                    sortable: true
                },
                {
                    text: this.$t('labels.year'),
                    value: 'year',
                    sortable: false
                },
                {
                    text: this.$t('labels.amount_per_year'),
                    value: 'count',
                    width: '70px',
                    sortable: false
                },
                {
                    text: this.$t('labels.amount_incorrect_per_year'),
                    value: 'incorrectCount',
                    width: '80px',
                    sortable: false
                },
                {
                    text: this.$t('labels.months.jan'),
                    value: 'month_1',
                    sortable: false
                },
                {
                    text: this.$t('labels.months.feb'),
                    value: 'month_2',
                    sortable: false
                },
                {
                    text: this.$t('labels.months.mar'),
                    value: 'month_3',
                    sortable: false
                },
                {
                    text: this.$t('labels.months.apr'),
                    value: 'month_4',
                    sortable: false
                },
                {
                    text: this.$t('labels.months.may'),
                    value: 'month_5',
                    sortable: false
                },
                {
                    text: this.$t('labels.months.jun'),
                    value: 'month_6',
                    sortable: false
                },
                {
                    text: this.$t('labels.months.jul'),
                    value: 'month_7',
                    sortable: false
                },
                {
                    text: this.$t('labels.months.aug'),
                    value: 'month_8',
                    sortable: false
                },
                {
                    text: this.$t('labels.months.sep'),
                    value: 'month_9',
                    sortable: false
                },
                {
                    text: this.$t('labels.months.oct'),
                    value: 'month_10',
                    sortable: false
                },
                {
                    text: this.$t('labels.months.nov'),
                    value: 'month_11',
                    sortable: false
                },
                {
                    text: this.$t('labels.months.dec'),
                    value: 'month_12',
                    sortable: false
                }
            ];
        }
    },
    methods: {
        getClass (i) {
            return i === 0 ? 'bordered' : 'none_bordered';
        },
        showInspectorReport (item) {
            this.$store.dispatch('reports/getInspectionsPerEmployee', {
                employee_id: item.employeeId,
                year: this.filters.year
            })
                .then(() => {
                    this.chosenEmployee = item.employeeId;
                    this.currentInspectorName = item.fullName;
                    this.inspectorReportVisibility = true;
                });
        },
        showRowDetails (event, { item }) {
            this.showInspectorReport(item);
        },
        closeInspectorReport () {
            this.inspectorReportVisibility = false;
            this.currentInspectorName = '';
            this.chosenEmployee = '';
        },
        emitGetFile (type) {
            this.$emit('get-file', type);
        },
        showSendMail () {
            this.sendMailVisibility = true;
        },
        sendMail () {
            this.agency = this.host;
            this.kindOfReport = 'incorrect';
            this.report = this.filters;

            return this.$store.dispatch('sendMail/sendReport');
        },
        closeSendMail () {
            this.sendMailVisibility = false;
            this.$store.commit('sendMail/RESET_MAIL_DATA');
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";
    .bordered {
        padding: 16px 0;
        border-bottom: 1px solid $grey_lighten_2;
    }
    .none_bordered {
        padding: 16px 0;
    }

    ::v-deep {
        & .v-data-table {
            & table {
                & tbody {
                    & tr {
                        & td {
                            padding: 0;
                        }
                    }
                }
                & .v-data-table-header__icon {
                    display: none;
                }
            }
        }
    }
</style>
