var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{staticClass:"mb-8",attrs:{"align-center":""}},[_c('GetFile',{staticClass:"mr-3",attrs:{"loader":_vm.pdfLoader},on:{"get-file":function($event){return _vm.emitGetFile('pdf')}}}),_c('GetFile',{attrs:{"title":_vm.$t('buttons.export_xls'),"loader":_vm.xlsLoader},on:{"get-file":function($event){return _vm.emitGetFile('xls')}}},[_c('template',{slot:"icon"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.ICONS.EXPORT))])],1)],2),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"secondary","small":""},on:{"click":_vm.showSendMail}},[_vm._v(_vm._s(_vm.$t('buttons.send_mail')))]),_c('SendMail',{attrs:{"dialog":_vm.sendMailVisibility,"callback":_vm.sendMail,"default-title":("" + (_vm.$t('labels.inspector_report')))},on:{"close":_vm.closeSendMail,"visibility":function($event){_vm.sendMailVisibility = $event}}})],1),_c('p',{staticClass:"text--xs mb-1"},[_c('span',{staticClass:"red--text font-weight-medium"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('messages.marked_incorrect_inspections')))]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":_vm.loader,"items":_vm.inspectionsPerEmployee,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([_vm._l((_vm.headers),function(headerItem,i){return {key:("header." + (headerItem.value)),fn:function(ref){
var header = ref.header;
var on = ref.on;
return [_c('SortableHeader',_vm._g({key:i,attrs:{"header-item":header}},on))]}}}),{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total.count)+"/"),_c('span',{staticClass:"red--text font-weight-medium"},[_vm._v(_vm._s(item.total.incorrectCount))])]}},_vm._l((12),function(i){return {key:("item.month_" + i),fn:function(ref){
var item = ref.item;
return [(item[("month_" + i)].count === 0 && (item.year < item.from_year || i < item.from_month))?_c('span',{key:i},[_vm._v("-")]):_c('span',{key:i},[_vm._v(" "+_vm._s(item[("month_" + i)].count)+"/"),_c('span',{staticClass:"red--text font-weight-medium"},[_vm._v(_vm._s(item[("month_" + i)].incorrectCount))])])]}}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }