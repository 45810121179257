import { render, staticRenderFns } from "./InspectorReportTable.vue?vue&type=template&id=9b511336&scoped=true&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
import script from "./InspectorReportTable.vue?vue&type=script&lang=js&"
export * from "./InspectorReportTable.vue?vue&type=script&lang=js&"
import style0 from "./InspectorReportTable.vue?vue&type=style&index=0&id=9b511336&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b511336",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VDataTable,VIcon,VLayout})
