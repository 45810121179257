<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-layout align-center
                  justify-space-between
                  class="mb-8">
            <v-row
                :class="{'nowrap': $vuetify.breakpoint.mdAndUp, 'px-3': $vuetify.breakpoint.smAndDown}">
                <v-col class="align-self-end">
                    <a class="mr-3"
                       @click="emitFetch">
                        <v-icon size="20">{{ ICONS.REFRESH }}</v-icon>
                        {{ $t('buttons.refresh_list') }}
                    </a>
                    <GetFile :title="$t('buttons.export_xls')"
                             :loader="xlsLoader"
                             data-test="getXls"
                             @get-file="emitGetFile('xls')">
                        <template slot="icon">
                            <v-icon class="mr-1"
                                    size="20">{{ ICONS.EXPORT }}
                            </v-icon>
                        </template>
                    </GetFile>
                </v-col>
                <v-col
                    lg="3" md="4" sm="6" cols="12">
                    <v-card
                        class="pa-1 mt-4 outlined tile">
                        <v-card-title>
                            {{ $t('etc.report.total_duration') }}
                        </v-card-title>
                        <v-card-text>
                            {{ totalDuration }}
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-layout>
        <v-data-table
            :headers="headers"
            :hide-default-header="$vuetify.breakpoint.smAndUp"
            disable-sort
            :loading="loader"
            :items="eztReports"
            disable-pagination
            hide-default-footer
            show-select
            v-model="selected"
            class="elevation-1">
            <template v-slot:header="{ props, on }"
                      v-if="$vuetify.breakpoint.smAndUp">
                <thead>
                <tr>
                    <th v-for="(headerItem, index) in props.headers"
                        :key="index">
                        <v-simple-checkbox
                            :ripple="false"
                            @input="on['toggle-select-all']"
                            v-if="headerItem.value === 'data-table-select'"
                            v-model="props.everyItem"
                            :indeterminate="props.someItems && !props.everyItem"
                            color="purple"
                        ></v-simple-checkbox>
                        <SortableHeader v-else :header-item="headerItem"
                                        @sort="sort"></SortableHeader>
                    </th>
                </tr>
                </thead>
            </template>
            <template v-slot:item.trwanie_kontroli=" { item } ">
                {{ getDuration(item.trwanie_kontroli) }}
            </template>
        </v-data-table>

        <Pagination :pagination="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { ICONS } from '@/constants/icons';
import GetFile from '@/components/widgets/buttons/GetFile';
import Pagination from '@/components/widgets/tables/Pagination';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'EZTReportsTable',
    components: { GetFile, SortableHeader, Pagination },
    mixins: [paginationSettings],
    props: {
        loader: {
            type: Boolean,
            'default': false
        },
        pdfLoader: {
            type: Boolean,
            'default': false
        },
        xlsLoader: {
            type: Boolean,
            'default': false
        },
        totalDuration: {
            type: String,
            'default': ''
        }
    },
    data: (vm) => ({
        pagination: vm.setupPagination(),
        ICONS,
        sendMailVisibility: false,
        selected: [],
        sortable: {
            num_row: 'desc',
            imie: 'asc',
            nazwisko: 'asc',
            klient: 'asc',
            obiekt: 'asc',
            wejscie: 'asc',
            wyjscie: 'asc',
            trwanie_kontroli: 'desc'
        }
    }),
    watch: {
        eztReportsTotalAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            }
        }
    },
    computed: {
        ...mapState('microserviceReports', [
            'eztReports',
            'eztReportsTotalAmount'
        ]),
        headers () {
            return [
                {
                    text: this.$t('ezt.report.seq'),
                    value: 'num_row',
                    sortable: true
                },
                {
                    text: this.$t('ezt.report.name'),
                    value: 'imie',
                    sortable: true
                },
                {
                    text: this.$t('ezt.report.surname'),
                    value: 'nazwisko',
                    sortable: true
                },
                {
                    text: this.$t('ezt.report.object'),
                    value: 'obiekt',
                    sortable: true
                },
                {
                    text: this.$t('ezt.report.client'),
                    value: 'klient',
                    sortable: true
                },
                {
                    text: this.$t('ezt.report.enter'),
                    value: 'wejscie',
                    sortable: true
                },
                {
                    text: this.$t('ezt.report.exit'),
                    value: 'wyjscie',
                    sortable: true
                },
                {
                    text: this.$t('ezt.report.duration'),
                    value: 'trwanie_kontroli',
                    sortable: true
                }
            ];
        }
    },
    methods: {
        getDuration (item) {
            let seconds = item;
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);

            seconds = String(seconds % 60).padStart(2, '0');
            minutes = String(minutes % 60).padStart(2, '0');
            hours = String(hours).padStart(2, '0');

            return `${hours}:${minutes}:${seconds}`;
        },
        emitGetFile (type) {
            this.$emit('get-file', type);
        },
        emitFetch () {
            this.$emit('fetch');
        },
        changePage (event) {
            this.pagination.page = event;
            this.$emit('change-page', event);
        },
        changeAmount (event) {
            this.pagination.amount = event;
            this.$emit('change-amount', event);
        },
        sort (event) {
            const val = event;
            switch (this.sortable[val]) {
            case null:
                this.sortable[val] = 'asc';
                break;
            case 'asc':
                this.sortable[val] = 'desc';
                break;
            case 'desc':
                this.sortable[val] = 'asc';
                break;
            }

            this.$emit('sort', {
                name: val,
                value: this.sortable[val]
            });
        }
    }

};
</script>

<style scoped>

</style>
