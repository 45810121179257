var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DefaultV2Table',{ref:"planningReportsTable",attrs:{"setup":_vm.getTableSetup},on:{"edit-item":function($event){return _vm.openForm($event)},"response":_vm.tableResponse,"delete-item":function($event){return _vm.deleteReport($event)},"show-item":function($event){return _vm.openDetails($event)}},scopedSlots:_vm._u([{key:"secondButton",fn:function(){return [_c('v-btn',{staticClass:"mx-3",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.openForm(true)}}},[_vm._v(_vm._s(_vm.$t('planning_reports.send_now'))+" ")])]},proxy:true},{key:"upperRow",fn:function(){return [_c('v-row',{staticClass:"px-4"},[_c('v-select',{attrs:{"loading":_vm.switchLoading,"outlined":"","items":_vm.getPlanningReportTypes,"label":_vm.$t('planning_reports.filter_reports')},on:{"change":_vm.switchTableMode},model:{value:(_vm.filterByReportType),callback:function ($$v) {_vm.filterByReportType=$$v},expression:"filterByReportType"}})],1)]},proxy:true},{key:"item.frequency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFrequencyType(item))+" ")]}},{key:"item.time_to_invoke_report",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.PARSE_TIME_FROM_ISO_DATESTRING(item.time_to_invoke_report))+" ")]}},{key:"item.end_date_reporting",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.PARSE_DATE(item.end_date_reporting))+" ")]}},{key:"item.is_paused",fn:function(ref){
var item = ref.item;
return [(item.is_paused)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.ICONS.ERROR_CIRCLE))]):_vm._e()]}}])}),_c('v-dialog',{attrs:{"persistent":true,"max-width":"1200px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('PlanningReportsForm',{ref:"planningForm",attrs:{"generateNow":_vm.generateNow,"itemTypes":_vm.getPlanningReportTypes,"frequencyTypes":_vm.getFrequencyReportTypes,"alert_email_sent":_vm.alert_email_sent,"alert_email_error":_vm.alert_email_error,"alert_message":_vm.alert_message,"alert_error":_vm.alert_error,"alert_type":_vm.alert_type,"saveIsDisabled":_vm.saveIsDisabled},on:{"save":_vm.updateReport,"create":_vm.getCreateAction,"close":_vm.closeForm}})],1),_c('v-dialog',{attrs:{"persistent":true,"max-width":"1200px"},model:{value:(_vm.detailsDialog),callback:function ($$v) {_vm.detailsDialog=$$v},expression:"detailsDialog"}},[_c('PlanningReportsDetails',{attrs:{"item":_vm.plannedReport,"itemTypes":_vm.getPlanningReportTypes,"frequencyTypes":_vm.getFrequencyReportTypes},on:{"close-details":_vm.closeDetails}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }