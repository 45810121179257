<template>
    <div>
        <DefaultV2Table :setup="getTableSetup"
                      @edit-item="openForm($event)"
                      @response="tableResponse"
                      @delete-item="deleteReport($event)"
                      @show-item="openDetails($event)"
                      ref="planningReportsTable">
            <template #secondButton>
                <v-btn class="mx-3" color="secondary"
                       @click="openForm(true)">{{ $t('planning_reports.send_now') }}
                </v-btn>
            </template>
            <template #upperRow>
                <v-row class="px-4">
                    <v-select
                        :loading="switchLoading"
                        outlined
                        :items="getPlanningReportTypes"
                        v-model="filterByReportType"
                        :label="$t('planning_reports.filter_reports')"
                        @change="switchTableMode"
                    ></v-select>
                </v-row>
            </template>
            <template v-slot:item.frequency="{item}">
                {{ getFrequencyType(item) }}
            </template>
            <template v-slot:item.time_to_invoke_report="{item}">
                {{ PARSE_TIME_FROM_ISO_DATESTRING(item.time_to_invoke_report) }}
            </template>
            <template v-slot:item.end_date_reporting="{item}">
                {{ PARSE_DATE(item.end_date_reporting) }}
            </template>
            <template v-slot:item.is_paused="{item}">
                <v-icon v-if="item.is_paused" color="red">{{ ICONS.ERROR_CIRCLE }}</v-icon>
            </template>
        </DefaultV2Table>
        <v-dialog v-model="dialog" :persistent="true" max-width="1200px">
            <PlanningReportsForm
                ref="planningForm"
                :generateNow="generateNow"
                :itemTypes="getPlanningReportTypes"
                :frequencyTypes="getFrequencyReportTypes"
                :alert_email_sent="alert_email_sent"
                :alert_email_error="alert_email_error"
                :alert_message="alert_message"
                :alert_error="alert_error"
                :alert_type="alert_type"
                :saveIsDisabled="saveIsDisabled"
                @save="updateReport"
                @create="getCreateAction"
                @close="closeForm">
            </PlanningReportsForm>
        </v-dialog>
        <v-dialog v-model="detailsDialog" :persistent="true" max-width="1200px">
            <PlanningReportsDetails
                :item="plannedReport"
                :itemTypes="getPlanningReportTypes"
                :frequencyTypes="getFrequencyReportTypes"
                @close-details="closeDetails">
            </PlanningReportsDetails>
        </v-dialog>
    </div>
</template>

<script>
import DefaultV2Table from '@/components/DefaultV2Table';
import PlanningReportsForm from '@/components/reports/PlanningReportsForm';
import PlanningReportsDetails from '@/components/reports/PlanningReportsDetails';
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import debouncedEmployees from '@/mixins/debounced/debouncedEmployees';
import debouncedEmployeesWithEmail from '@/mixins/debounced/debouncedEmployeesWithEmail';
import { FREQUENCY_TYPES_ENUM, PERIODIC_REPORTS_TYPES } from '@/constants/reports';
import { PERIODIC_REPORTS_PARAMS } from '@/constants/reports_params';
import { ICONS } from '@/constants/icons';
import { PARSE_TIME_FROM_ISO_DATESTRING, PARSE_DATE } from '@/helpers/dates';

export default {
    name: 'PlanningReports',
    components: { DefaultV2Table, PlanningReportsForm, PlanningReportsDetails },
    mixins: [
        debouncedEmployees,
        debouncedEmployeesWithEmail
    ],
    data: () => ({
        ICONS,
        PERIODIC_REPORTS_TYPES,
        PERIODIC_REPORTS_PARAMS,
        PARSE_TIME_FROM_ISO_DATESTRING,
        PARSE_DATE,
        dialog: false,
        switchLoading: false,
        detailsDialog: false,
        generateNow: false,
        alert_email_sent: false,
        alert_email_error: false,
        alert_message: null,
        alert_error: null,
        alert_type: 'info',
        saveIsDisabled: false
    }),
    computed: {
        ...mapState('manageLocation', [
            'activeLocation'
        ]),
        ...mapFields('manageLocation', [
            'locationFormVisibility',
            'editedLocationId',
            'showTab'
        ]),
        ...mapFields('managePlannedReports', [
            'plannedReport',
            'editedPlannedReportId',
            'filterByReportType'
        ]),
        getPlanningReportTypes () {
            const selectReports = [];
            for (const reportType of this.PERIODIC_REPORTS_TYPES) {
                selectReports.push({ text: this.$t(reportType.name), value: reportType.type });
            }
            return selectReports;
        },
        getFrequencyReportTypes () {
            return [
                { text: this.$t(`planning_reports.frequency_types.${FREQUENCY_TYPES_ENUM.DAILY}`), value: FREQUENCY_TYPES_ENUM.DAILY },
                { text: this.$t(`planning_reports.frequency_types.${FREQUENCY_TYPES_ENUM.EVERY_MONDAY}`), value: FREQUENCY_TYPES_ENUM.EVERY_MONDAY },
                { text: this.$t(`planning_reports.frequency_types.${FREQUENCY_TYPES_ENUM.MONTHLY}`), value: FREQUENCY_TYPES_ENUM.MONTHLY }
            ];
        },
        paramString () {
            if (this.isTypeOf(this.PERIODIC_REPORTS_PARAMS.POINT)) {
                return 'point';
            } else if (this.isTypeOf(this.PERIODIC_REPORTS_PARAMS.LOCATION)) {
                return 'location';
            } else if (this.isTypeOf(this.PERIODIC_REPORTS_PARAMS.EMPLOYEE)) {
                return 'employee';
            }
            return '';
        },
        getTableSetup () {
            return {
                title: this.$t('planning_reports.title'),
                newItemTitle: this.$t('planning_reports.add_schedule'),
                deleteInfo: this.$t('messages.delete_confirmation'),
                searchBy: `${this.paramString}_name`,
                customSearchFilter: 'search',
                headers: [
                    {
                        text: this.$t('labels.seq'),
                        value: 'seq',
                        sortable: false
                    },
                    {
                        text: this.$t(`labels.${this.paramString}`),
                        value: `${this.paramString}_name`,
                        icon: false,
                        sortable: true
                    },
                    {
                        text: this.$t('planning_reports.headers.frequency'),
                        value: 'frequency',
                        icon: false,
                        sortable: false
                    },
                    {
                        text: this.$t('planning_reports.headers.generating_time'),
                        value: 'time_to_invoke_report',
                        icon: false,
                        sortable: false
                    },
                    {
                        text: this.$t('planning_reports.headers.end_date'),
                        value: 'end_date_reporting',
                        icon: false,
                        sortable: false
                    },
                    {
                        text: this.$t('planning_reports.headers.is_paused'),
                        value: 'is_paused',
                        icon: false,
                        sortable: false
                    },
                    {
                        text: this.$t('labels.actions'),
                        value: 'actions',
                        align: 'end',
                        width: '128px',
                        sortable: false
                    }
                ],
                endpoint: { 'group': this.PERIODIC_REPORTS_TYPES.find(r => r.type === this.filterByReportType)?.endpointGroup, 'endpoint': 'list' },
                customItems: this.customItems,
                ability: {
                    c: this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.CUSTOM_REPORTS),
                    r: this.$can(this.$abilityActions.READ, this.$abilitySubjects.CUSTOM_REPORTS),
                    u: this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.CUSTOM_REPORTS),
                    d: this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.CUSTOM_REPORTS)
                },
                sortable: {
                    'point_name': 'asc',
                    'location_name': 'asc',
                    'employee_name': 'asc'
                },
                sortingKeys: {
                    'point_name': 'point_name',
                    'location_name': 'location_name',
                    'employee_name': 'employee_name'
                }
            };
        }
    },
    methods: {
        customItems (items) {
            return items.map(e => {
                return {
                    ...e,
                    point_name: e.point?.name || null,
                    location_name: e.location?.name || null,
                    employee_name: e.employee?.full_name || null
                };
            });
        },
        setAlertSendNow (message) {
            // disable the button so email isn't sent twice by accident
            this.$data.saveIsDisabled = true;
            this.$data.alert_email_sent = true;
            this.$data.alert_message = message;
            this.$data.alert_type = 'info';
            // hide popup after 5s
            setTimeout(() => { this.$data.alert_email_sent = false; }, 5000);
            // enable the button after 5s
            setTimeout(() => { this.$data.saveIsDisabled = false; }, 5000);
        },
        setAlertError (message) {
            this.$data.saveIsDisabled = true;
            this.$data.alert_email_error = true;
            this.$data.alert_error = message;
            this.$data.alert_type = 'error';
            setTimeout(() => { this.$data.alert_email_error = false; }, 5000);
            setTimeout(() => { this.$data.alert_error = null; }, 5000);
            setTimeout(() => { this.$data.saveIsDisabled = false; }, 5000);
        },
        getCreateAction () {
            this.generateNow ? this.sendPeriodicReport() : this.createReport();
        },
        getFrequencyType (item) {
            return item.only_monday
                ? this.$t('planning_reports.frequency_types.every_monday') : item.monthly
                    ? this.$t('planning_reports.frequency_types.monthly') : this.$t('planning_reports.frequency_types.daily');
        },
        openForm (item) {
            if (item === null) {
                this.dialog = true;
            } else if (typeof item === 'boolean') {
                this.generateNow = true;
                this.dialog = true;
            } else if (typeof item === 'object') {
                this.editedPlannedReportId = item.id;
                const reportType = this.PERIODIC_REPORTS_TYPES.find(r => r.type === this.filterByReportType);
                if (reportType) {
                    this.$store.dispatch('managePlannedReports/getReport', { reportType: reportType, id: item.id }).then(() => {
                        this.dialog = true;
                    });
                }
            }
        },
        openDetails (item) {
            this.editedPlannedReportId = item.id;
            const reportType = this.PERIODIC_REPORTS_TYPES.find(r => r.type === this.filterByReportType);
            if (reportType) {
                this.$store.dispatch('managePlannedReports/getReport', { reportType: reportType, id: item.id }).then(() => {
                    if (this.isTypeOf(this.PERIODIC_REPORTS_PARAMS.POINT)) {
                        this.$store.dispatch('locations/getLocations', { 'filter[id]': item.point_id }).then(() => {
                            this.detailsDialog = true;
                        });
                    } else if (this.isTypeOf(this.PERIODIC_REPORTS_PARAMS.LOCATION)) {
                        this.$store.dispatch('sites/getSites', { 'filter[id]': item.location_id }).then(() => {
                            this.detailsDialog = true;
                        });
                    } else if (this.isTypeOf(this.PERIODIC_REPORTS_PARAMS.EMPLOYEE)) {
                        this.$store.dispatch('employees/getEmployees', { 'filter[id]': item.employee_id }).then(() => {
                            this.detailsDialog = true;
                        });
                    }
                });
            }
        },
        closeDetails () {
            this.detailsDialog = false;
            this.$store.dispatch('managePlannedReports/clearPlannedReport');
            this.editedPlannedReportId = null;
        },
        closeForm () {
            this.dialog = false;
            this.loader = false;
            this.generateNow = false;
            this.$store.dispatch('managePlannedReports/clearPlannedReport');
            this.$refs.planningForm.resetForm();
            this.$refs.planningReportsTable.fetchData();
        },
        createReport () {
            this.$refs.planningForm.validate()
                .then(valid => {
                    if (valid) {
                        this.loader = true;
                        this.$store.dispatch('managePlannedReports/createReport').then(() => {
                            this.closeForm();
                        });
                    }
                });
        },
        switchTableMode () {
            this.switchLoading = true;
            this.$nextTick(() => {
                this.fetchData();
            });
        },
        sendPeriodicReport () {
            this.loader = true;
            this.$refs.planningForm.validate().then(valid => {
                if (valid) {
                    // sent notification about email sent
                    this.setAlertSendNow(this.$t('planning_reports.email_sent'));

                    return this.$store.dispatch('managePlannedReports/sendReport').then(() => {
                        this.$data.alert_message = this.$t('planning_reports.email_sent_done');
                        this.$data.alert_type = 'success';

                        setTimeout(() => { this.closeForm(); }, 2000);
                    }).catch((e) => {
                        this.$data.alert_email_sent = false;
                        this.setAlertError(e);
                    });
                }
            });
        },
        updateReport () {
            this.loader = true;
            this.$refs.planningForm.validate().then(valid => {
                if (valid) {
                    return this.$store.dispatch('managePlannedReports/updateReport').then(() => {
                        this.closeForm();
                    });
                }
            });
        },
        deleteReport (item) {
            this.loader = true;
            const reportType = this.PERIODIC_REPORTS_TYPES.find(r => r.type === this.filterByReportType);
            if (reportType) {
                this.$store.dispatch('managePlannedReports/deleteReport', { reportType: reportType, id: item.id }).then(() => {
                    this.$refs.planningReportsTable.fetchData();
                });
            }
        },
        fetchData () {
            this.$refs.planningReportsTable.resetSorting();
            this.$refs.planningReportsTable.fetchData();
        },
        tableResponse (res) {
            if (res.status === 200) {
                this.switchLoading = false;
            }
        },
        isTypeOf (type) {
            return this.PERIODIC_REPORTS_TYPES.find(r => r.type === this.filterByReportType)?.param === type;
        }
    }
};
</script>
